/* eslint-disable dot-notation */
/** @format */
import { useQuery } from "@apollo/client";
import {
  READ_CURRENT_USER_AUTH,
  READ_CURRENT_USER_BASIC,
  READ_FEATURE_BY_PAGE,
  READ_USER_DETAILS,
} from "@roadflex/graphql";
import {
  FeaturePermissionsType,
  UserAuthType,
  UserBasicType,
  UserDetailsType,
} from "@roadflex/types";

export function useReadPermissions({ pageName }: { pageName: string }) {
  const { data, refetch, ...rest } = useQuery(READ_FEATURE_BY_PAGE, {
    // fetchPolicy: "no-cache",
    variables: { data: { pageName: pageName } },
  });
  const {
    readFeatureByPage = null,
  }: { readFeatureByPage: FeaturePermissionsType | null } = data || {};
  return { readFeatureByPage, refetch, ...rest };
}

export function useReadCurrentUserAuth() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_AUTH, {
    fetchPolicy: "no-cache",
  });
  const {
    readCurrentUserAuth = null,
  }: { readCurrentUserAuth: UserAuthType | null } = data || {};
  return { readCurrentUserAuth, refetch, ...rest };
}

export function useReadCurrentUserBasicInformation() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_BASIC, {
    // fetchPolicy: "no-cache",
  });
  const { readCurrentUserBasic }: { readCurrentUserBasic: UserBasicType } =
    data || {};

  return { readCurrentUserBasic, refetch, ...rest };
}

export const useReadUserDetails = (
  email: string,
  phoneNumber: string,
  businessName: string,
  queryType: string,
  objArray: string[],
) => {
  const {
    data: readUserDetailsData,
    refetch: readUserDetailsRefetch,
    loading: readUserDetailsLoading,
    ...rest
  } = useQuery<{
    readUserDetails: {
      code: string;
      message: string;
      details: UserDetailsType;
    };
  }>(READ_USER_DETAILS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        email: email,
        phoneNumber: phoneNumber,
        businessName: businessName,
        queryType: queryType,
        objArray: objArray,
      },
    },
  });

  return {
    readUserDetailsData,
    readUserDetailsRefetch,
    readUserDetailsLoading,
    ...rest,
  };
};
